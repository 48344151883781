import React, { useContext } from 'react';
import { Route, ScrollToTop, Routes, useLocation } from '@thd-olt-component-react/router';
import { string, number, shape, func } from 'prop-types';
import { AppContext } from '@thd-nucleus/app-render';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { NoIndexNoFollowMetadata, Metadata } from '@thd-olt-component-react/metadata';

import { Error } from './pages';
import { getRoutes } from './routes';

export const App = ({
  pipTimeout,
  prefetchPip,
  productPodHoverDelay,
  data,
  cookieUtils
}) => {
  const { instance } = useContext(AppContext);
  const {
    currentRouteName,
    currentRouteComponent
  } = instance.router;

  const routes = getRoutes(currentRouteName, currentRouteComponent);
  const { channel } = useContext(ExperienceContext);
  const { search: querystring, pathname } = useLocation();

  const pathWithQueryParams = `${pathname}${querystring}`;
  const isPreviewLink = !!instance?.headers?.['x-content-preview']
    || pathWithQueryParams.indexOf('render*') > -1
    || pathWithQueryParams.indexOf('contentPV') > -1
    || pathWithQueryParams.indexOf('bassPV') > -1;

  return (
    <>
      {isPreviewLink && (
        <Metadata>
          <NoIndexNoFollowMetadata />
        </Metadata>
      )}
      <ScrollToTop />
      <Routes>
        <Route path={routes.colorWall.path} element={<routes.colorWall.component data={data} />} />
        <Route
          path={routes.browse.path}
          element={channel === 'mobile'
            ? (
              <routes.browseMobile.component
                pageType="browse"
                prefetchPip={prefetchPip}
                productPodHoverDelay={productPodHoverDelay}
              />
            )
            : (
              <routes.browse.component
                pageType="browse"
                prefetchPip={prefetchPip}
                productPodHoverDelay={productPodHoverDelay}
              />
            )}
        />

        <Route
          path={routes.search.path}
          element={channel === 'mobile'
            ? (
              <routes.searchMobile.component
                pageType="search"
                prefetchPip={prefetchPip}
                productPodHoverDelay={productPodHoverDelay}
              />
            )
            : (
              <routes.search.component
                pageType="search"
                prefetchPip={prefetchPip}
                productPodHoverDelay={productPodHoverDelay}
              />
            )}
        />
        <Route
          exact
          path={routes.bundle.path}
          element={<routes.bundle.component timeout={pipTimeout} cookieUtils={cookieUtils} data={data} />}
        />
        <Route
          exact
          path={routes.product.path}
          element={channel === 'mobile'
            ? <routes.productMobile.component pipTimeout={pipTimeout} cookieUtils={cookieUtils} />
            : <routes.product.component pipTimeout={pipTimeout} cookieUtils={cookieUtils} />}
        />
        <Route
          element={channel === 'mobile' ? <routes.pepMobile.component /> : <routes.pep.component />}
          exact
          path={routes.pep.path}
        />
        <Route exact path={routes.compare.path} element={<routes.compare.component />} />
        <Route exact path={routes.home.path} element={<routes.home.component />} />
        <Route path="/error" element={<Error />} />
        <Route element={<Error />} />
      </Routes>
    </>
  );
};

App.propTypes = {
  pipTimeout: string,
  prefetchPip: string,
  productPodHoverDelay: number,
  data: shape({}),
  cookieUtils: shape({}),
};

App.defaultProps = {
  pipTimeout: null,
  prefetchPip: null,
  productPodHoverDelay: 500,
  data: null,
  cookieUtils: null
};